<template>
  <product title="Amazfit GTR 3"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="899"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100014760127.html"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'Amazfit-GTR-3',
  components: {
    Product
  },
  data () {
    return {
      slogan: '数字表冠｜全方位健康监测｜Zepp OS 丰富手表小程序生态｜21 天超长澎湃续航',
      colors: [
        '曜石黑',
        '云母灰'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3/2_pro_04.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_4.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_5.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_6.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_7.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_8.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTR-3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_10.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_11.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3/GTR3_12.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
